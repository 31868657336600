<template>
  <div class="tag-container">
    <div class="operator-bar">
      <el-button type="primary" icon="el-icon-edit" @click="handleCreate">新建标签</el-button>
    </div>

    <el-table :data="listData" max-height="618" :v-loading="loading == 'list'">
      <el-table-column type="index" :index="indexMethod" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="tagName" label="标签名称" min-width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="150">
        <template slot-scope="{ row }" v-if="row.createTime">
          {{ row.createTime | formatTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" prop="status">
        <template slot-scope="{row}">
          <el-button type="primary" @click="handleUpdate(row)" size="mini">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />

    <!-- 表单 -->
    <el-dialog :title="uploadDialog.title" :visible.sync="uploadDialog.visible" width="500px">
      <el-form :model="formData" ref="form" :rules="formRules">
        <el-form-item label="标签名称：" label-width="100px" prop="tagName">
          <el-input v-model="formData.tagName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog.visible = false">取 消</el-button>
        <el-button type="primary" v-loading="loading == 'submit'" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getTagListCount, getTagList, createTag, updateTag} from '@/api/tag'
import { parseTime } from '@/utils/tool.js'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      listData:[],
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      },
      formData: {
        id:'',
        title: ''
      },
      uploadDialog: {
        title:'',
        visible:false
      },
      formRules: {
        tagName:[{ required: true, message: '请输入标签名称', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getListCount()
    this.getList()
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    getListCount() {
      getTagListCount().then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      const params = {
        page: this.listPage.page,
        pageSize: this.listPage.pageSize
      }
      this.loading = 'list'
      getTagList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    handleCreate() {
      this.formData = {
        tagId:'',
        tagName: ''
      }
      this.uploadDialog = {
        title: '新建标签',
        visible: true
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    handleUpdate(row) {
      this.formData = {
        tagId: row.tagId,
        tagName: row.tagName
      }
      this.uploadDialog = {
        title: '编辑标签',
        visible: true
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if(valid){
          this.loading = 'submit'
          try {
            if(this.formData.tagId){
              await  updateTag(this.formData)
            } else {
              await createTag(this.formData)
            }
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.getListCount()
            this.getList()
            this.loading = false
            this.uploadDialog.visible = false
          }catch {
            this.loading = false
          }
        }
      })
    }
  },
  filters: {
    formatTime(time, format) {
      return parseTime(time, format)
    }
  }
}
</script>
